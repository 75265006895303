
import Vue, { PropType } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Analytics from './subViews/Analytics.vue'
import TaskList from './subViews/TaskList.vue'
import { Connector, Project } from '../../types/main'
import ActivationChangedSnackbar from '../../components/chisai/GCB2/activation/ChangedSnackbar.vue'
import ModaChangedSnackbar from '../../components/chisai/GCB2/moda/ChangedSnackbar.vue'
import Overview from './subViews/Overview.vue'
import GCB2Sidebar from '@/components/chisai/GCB2/Sidebar.vue'
import NewDataSnackbar from '../../components/chisai/GCB2/NewDataSnackbar.vue'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import fb from '@/plugins/firebase'
import _, { isNil } from 'lodash'
import { checkRole } from '../../helpers/roles'
import CallReport from './subViews/CallReport.vue'
import BotTriggersOverview from './subViews/BotTriggersOverview.vue'
import { CONNECTOR_TYPES } from '../../vars/general'
const PAGE_STATES = {
    PAGE_LOADING: 'PAGE_LOADING',
    DASHBOARD: 'DASHBOARD',
    DATA_LOADING: 'DATA_LOADING',
    INITIALIZING: 'INITIALIZING',
    ERROR: 'ERROR',
}
export default Vue.extend({
    name: 'GCB2Main',
    components: {
        Breadcrumbs,
        Analytics,
        TaskList,
        ActivationChangedSnackbar,
        ModaChangedSnackbar,
        Overview,
        GCB2Sidebar,
        NewDataSnackbar,
    },

    data: () => ({
        sidebarIsOpen: false,
        activeTabNum: null as number | null | undefined,
        PAGE_STATES,
    }),
    watch: {
        activeTabNum() {
            if (!this.tabs.length) return

            const sectionName = this.tabs[this.activeTabNum || 0]!.sectionName
            this.$router.replace({
                path: this.$router.currentRoute.path,
                query: Object.assign({}, this.$router.currentRoute.query, { section: sectionName }),
            })
        },
        '$route.query.section'() {
            this.gcb2Store.closeSidebar()
            const sectionName = this.$router.currentRoute.query.section
            const tubNum = this.tabs.findIndex(el => el.sectionName === sectionName)
            this.activeTabNum = tubNum
        },
    },
    computed: {
        tabs(): any[] {
            return [
                {
                    tabName: 'БОТ',
                    sectionName: 'bot',
                    component: BotTriggersOverview,
                    showCondition: Boolean(
                        this.project.connectors.find(
                            el => el.connectorType === CONNECTOR_TYPES.whatsappBasis
                            ) &&
                            (this.project.connectors.find(
                                el => el.connectorType === CONNECTOR_TYPES.yClients
                            ) ||
                                this.project.connectors.find(
                                    el => el.connectorType === CONNECTOR_TYPES.dikidi
                                ))
                    ),
                },
                {
                    tabName: 'ОБЗОР',
                    sectionName: 'overview',
                    component: Overview,
                    showCondition: this.checkRole(this.project.id, 'gcb2:tasklist:view'),
                },
                {
                    tabName: 'СПИСОК ЗАДАЧ',
                    sectionName: 'taskList',
                    component: TaskList,
                    showCondition: this.checkRole(this.project.id, 'gcb2:tasklist:view'),
                },
                {
                    tabName: 'АНАЛИТИКА',
                    sectionName: 'analytics',
                    component: Analytics,
                    showCondition: this.checkRole(this.project.id, 'gcb2:analytics:view'),
                },
                {
                    tabName: 'ВЫГРУЗКА НА ОБЗВОН',
                    sectionName: 'callReport',
                    component: CallReport,
                    showCondition: this.project.id === 'X8SMXU15M', //Nailmaker bar
                },
            ].filter(el => (_.isUndefined(el.showCondition) ? true : el.showCondition))
        },
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(con => con.connectorType === 'GCB2')
        },

        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Кого из клиентов стоит позвать',
                    to: '',
                    disabled: true,
                },
            ]
        },
    },
    methods: { checkRole },
    async created() {
        const section = this.$router.currentRoute.query.section

        if (section) {
            this.activeTabNum = this.tabs.findIndex(el => el.sectionName === section)
        } else {
            this.activeTabNum = this.tabs.findIndex(el => el.sectionName === 'overview')
        }
    },
    mounted() {},
})
